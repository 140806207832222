<template>
  <div>
    <!--begin::customer-->
    <!--    <div class="card card-custom">-->
    <div>
      <div v-if="PlanMessage? true: false" class="card-header d-block border-0 pt-6 pb-0">
        <div>
          <b-alert show variant="danger">{{ PlanMessage }}</b-alert>
        </div>
      </div>

      <!--      <div class="card-header flex-wrap border-0 pt-6 pb-0">-->
      <!--        <div class="card-title m-0">-->
      <h3 class="card-label">
        {{ $t('MENU.laundry_sales_invoices_management') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>
      <!--        </div>-->
      <!--      </div>-->

      <b-tabs content-class="mt-3" class="nav-custom-link">
        <b-tab :title="$t('sales_invoices.basic_information')" @click="toggleTab('basic_information')">
          <div class="row">
            <div class="col-md-6">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-12 mb-5">
                    <label>{{ $t('sales_invoices.customer') }}<span class="text-danger">*</span></label>
                    <div class="row">
                      <div class="col-sm-8">
                        <multiselect
                            id="customer_id"
                            v-model="customer"
                            :placeholder="$t('sales_invoices.customer')"
                            label="fullname"
                            track-by="id"
                            :options="customers"
                            :multiple="false"
                            :taggable="false"
                            :show-labels="false"
                            :show-no-options="false"
                            :show-no-results="false"
                            @search-change="getCustomers($event)">
                        </multiselect>
                        <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('sales_invoices.customer') }}.</span>
                        <span v-if="validation && validation.customer_id" class="fv-plugins-message-container invalid-feedback">
                                                {{ validation.customer_id[0] }}
                                            </span>
                      </div>
                      <div class="col-sm-4">
                        <button class="btn btn-primary" @click="showModal">{{ $t('add_new') }}</button>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <b-card no-body class="mt-5">
                <b-card-header header-tab="header" class="p-1" role="tab">
                  <b-button block v-b-toggle.accordion-6 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
                    {{ $t('sales_invoices.received_and_delivery_appointment') }}
                  </b-button>
                </b-card-header>

                <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel">
                  <b-card-body class="row">

                    <div class="col-md-12 d-flex justify-content-between pt-2 pb-2">
                      <div class="d-flex my-auto pl-3 pr-3">
                        <b class="cursor-pointer">{{ $t('sales_invoices.received') }}</b>
                      </div>
                      <div class="d-flex">
                        <input type="date" class="form-control" v-model="data.received_date">
                        <input type="time" class="form-control" v-model="data.received_time">
                      </div>
                    </div>
                    <div class="col-md-12 d-flex justify-content-between pt-2 pb-2 mt-3">
                      <div class="d-flex my-auto pl-3 pr-3">
                        <b class="cursor-pointer">{{ $t('sales_invoices.delivery') }}</b>
                      </div>
                      <div class="d-flex">
                        <input type="date" class="form-control" v-model="data.delivery_date">
                        <input type="time" class="form-control" v-model="data.delivery_time">

                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body v-if="customer" class="mt-5">
                <b-card-header header-tab="header" class="p-1" role="tab">
                  <b-button block v-b-toggle.accordion-3 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
                    {{ $t('customer_data_details') }}
                  </b-button>
                </b-card-header>

                <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                  <b-card-body class="row">
                    <div class="col-lg-12 mb-5">
                      <p><b>{{ $t('name') }}: </b> {{ customer.fullname }}</p>
                      <p><b>{{ $t('mobile') }}: </b> {{ customer.mobile }}</p>
                      <p><b>{{ $t('email') }}: </b> {{ customer.email }}</p>
                      <p><b>{{ $t('tax_no') }}: </b> {{ customer.tax_register }}</p>
                    </div>
                    <div class="col-md-12 mb-5">
                      <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
                        <h6 class="my-auto text-white">{{ $t('customers.balances') }}</h6>
                      </div>
                      <table class="table table-row-bordered">
                        <thead>
                        <tr>
                          <th>{{ $t('customers.balance') }}</th>
                          <th>{{ $t('customers.debit') }}</th>
                          <th>{{ $t('customers.currency') }}</th>
                          <th>{{ $t('customers.last_validated_date') }}</th>
                          <th></th>
                        </tr>

                        </thead>
                        <tbody>
                        <template v-if="customer.balances && customer.balances.length > 0">
                          <tr v-for="(row, index) in customer.balances" :key="index">
                            <td>{{ row.credit }}</td>
                            <td>{{ row.debit }}</td>
                            <td>{{ row.currency_name }}</td>
                            <td>{{ row.last_validated_date }}</td>
                          </tr>
                        </template>
                        <tr v-else>
                          <td colspan="4">{{ $t('no_balances') }}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mt-5">
                <b-card-header header-tab="header" class="p-1" role="tab">
                  <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
                    {{ $t('price_list_and_accounts') }}
                  </b-button>
                </b-card-header>

                <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                  <b-card-body class="row">
                    <div class="col-lg-6 mb-5">
                      <label>{{ $t('sales_invoices.price_list') }}</label>
                      <div class="input-group">

                        <multiselect v-model="price_list"
                                     :placeholder="$t('sales_invoices.price_list')"
                                     label="name"
                                     track-by="id"
                                     :options="pricesLists"
                                     :multiple="false"
                                     :taggable="false"
                                     :show-labels="false"
                                     :show-no-options="false"
                                     @input="getAndSetPriceListToRepeater"
                                     :show-no-results="false">
                        </multiselect>
                        <div class="input-group-prepend">
                          <a class="btn btn-primary" href="/items/price-lists" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                        </div>
                      </div>
                      <span v-if="validation && validation.price_list_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.price_list_id[0] }}
                                    </span>
                    </div>

                    <div class="col-lg-6 mb-5">
                      <label>{{ $t('sales_invoices.accounts') }}</label>
                      <div class="input-group">
                        <multiselect
                            v-model="account"
                            :placeholder="$t('sales_invoices.accounts')"
                            label="label"
                            track-by="id"
                            :options="accounts"
                            :multiple="false"
                            :taggable="false"
                            :show-labels="false"
                            :show-no-options="false"
                            :show-no-results="false"
                            @search-change="getAccounts($event)">
                        </multiselect>
                        <div class="input-group-prepend">
                          <a class="btn btn-primary" href="/accounting/chart-accounts" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                        </div>
                      </div>
                      <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('sales_invoices.accounts') }}.</span>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mt-5" v-if="data.customer_id">
                <b-card-header header-tab="header" class="p-1" role="tab">
                  <b-button block v-b-toggle.accordion-5 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
                    {{ $t('shipping_address') }}
                  </b-button>
                </b-card-header>
                <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
                  <b-card-body class="row">

                    <div class="col-lg-12 mb-5">
                      <label>{{ $t('customer_address') }}</label>
                      <div class="input-group">
                        <multiselect
                            v-model="customer_address"
                            :placeholder="$t('customer_address')"
                            label="label"
                            track-by="id"
                            :options="customer_addresses"
                            :multiple="false"
                            :taggable="false"
                            :show-labels="false"
                            :show-no-options="false"
                            :show-no-results="false">
                        </multiselect>
                        <div class="input-group-prepend">
                          <a class="btn btn-primary" href="javascript:;" @click="showCustomerAddressModal"><i class="fa fa-plus" style="padding: 0"></i></a>
                        </div>
                      </div>

                    </div>
                    <div class="col-lg-12 mb-5" v-if="customer_address">
                      <p><b>{{ $t('customers.country') }}:</b> {{ customer_address.country_name }}</p>
                      <p><b>{{ $t('customers.city') }}:</b> {{ customer_address.city_name }}</p>
                      <p><b>{{ $t('customers.state') }}:</b> {{ customer_address.state }}</p>
                      <p><b>{{ $t('customers.postal_code') }}:</b> {{ customer_address.postal_code }}</p>
                      <p><b>{{ $t('customers.address_1') }}:</b> {{ customer_address.address_1 }}</p>
                      <p><b>{{ $t('customers.address_2') }}:</b> {{ customer_address.address_2 }}</p>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mt-5">
                <b-card-header header-tab="header" class="p-1" role="tab">
                  <b-button block v-b-toggle.accordion-15 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
                    {{ $t('applicable_taxes') }}
                  </b-button>
                </b-card-header>

                <b-collapse id="accordion-15" accordion="my-accordion" role="tabpanel">
                  <b-card-body class="row">
                    <div class="col-lg-12 mb-5" v-for="(taxes_rules, index) in taxes_rules_for_tax_setting" :key="'tax_rule'+index">
                      <b-form-checkbox :id="'checkbox-'+index" v-model="data.apply_tax_rules" :name="'checkbox-'+index" :value="{tax_rule_id: taxes_rules.id}">
                        {{ taxes_rules.name }}
                      </b-form-checkbox>
                    </div>


                  </b-card-body>
                </b-collapse>
              </b-card>

            </div>
            <div class="col-md-6">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('sales_invoices.invoice_code') }}</label>
                    <input type="text" v-model="data.invoice_code" class="form-control" :class="validation && validation.invoice_code ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.invoice_code" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.invoice_code[0] }}
                            </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('sales_invoices.reference_number') }}</label>
                    <input type="text" v-model="data.reference_number" class="form-control" :class="validation && validation.reference_number ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.reference_number" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.reference_number[0] }}
                            </span>
                  </div>

                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('sales_invoices.invoice_date') }}</label>
                    <input type="date" v-model="data.invoice_date" class="form-control" :class="validation && validation.invoice_date ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.invoice_date" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.invoice_date[0] }}
                                    </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('sales_invoices.issue_date') }}</label>
                    <input type="date" v-model="data.issue_date" class="form-control" :class="validation && validation.issue_date ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.issue_date" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.issue_date[0] }}
                                    </span>
                  </div>


                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('sales_invoices.currency') }}<span class="text-danger">*</span></label>
                    <div class="input-group">
                      <select name="" id="currency_id" v-model="data.currency_id" class="custom-select" :class="validation && validation.currency_id ? 'is-invalid' : ''">
                        <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
                      </select>
                      <div class="input-group-prepend">
                        <a class="btn btn-primary" href="/settings/currencies" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                      </div>
                    </div>
                    <span v-if="validation && validation.currency_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.currency_id[0] }}
                                    </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('sales_invoices.inventory') }}<span class="text-danger">*</span></label>
                    <div class="input-group">
                      <multiselect v-model="inventory"
                                   :placeholder="$t('open_stock_items.inventory')"
                                   label="name"
                                   track-by="id"
                                   :options="inventories"
                                   :multiple="false"
                                   :class="validation && validation.inventory_id ? 'is-invalid' : ''"
                                   :taggable="false"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false">
                      </multiselect>
                      <div class="input-group-prepend">
                        <a class="btn btn-primary" href="/settings/inventories" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                      </div>
                    </div>
                    <span v-if="validation && validation.inventory_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.inventory_id[0] }}
                                    </span>
                  </div>

                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('sales_invoices.payment_terms') }}</label>
                    <div class="row">
                      <div class="col-sm-9">
                        <input type="number" v-model="data.payment_terms" class="form-control" :class="validation && validation.payment_terms ? 'is-invalid' : ''"/>
                        <span v-if="validation && validation.payment_terms" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.payment_terms[0] }}
                                    </span>
                      </div>
                      <span class="col-sm-3">{{ $t('purchases_invoices.day_s') }}</span>
                    </div>
                  </div>

                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('status') }}</label>
                    <select name="" id="f_status" v-model="data.status" type="text" class="custom-select" :class="validation && validation.status ? 'is-invalid' : ''">
                      <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.title }}</option>
                    </select>
                    <span v-if="validation && validation.status" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.status[0] }}
                                    </span>
                  </div>

                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('is_apply_taxes_invoice') }}:</label>
                    <b-form-checkbox size="lg" :disabled="!is_company_has_tax_no" v-model="data.is_apply_taxes_invoice" name="check-button" switch></b-form-checkbox>
                  </div>
                </div>
              </div>


            </div>
          </div>


          <div class="row mt-5">
            <div class="col-md-12">
              <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
                <h6 class="mt-2">{{ $t('sales_invoices.items') }}</h6>
                <div class="d-flex">
                  <import-excel-data
                      :btn-class="'btn btn-primary btn-sm'"
                      @data="readDataFromExcel"
                      :inner-id="'1'"
                      :placeholder="$t('import_data')"
                      :template-link="'template/templateRepeaterItems'"
                  ></import-excel-data>
                  <button type="button" class="btn btn-primary" @click="addItemRowToList">{{ $t('add_more') }}</button>
                </div>
              </div>
              <div class="table-responsive bg-white pb-5">
                <!--                <table class="table table-row-bordered w-max-content" @keyup.enter="addItemRowToList" @keyup.46="removeItemRowFromList">-->
                <table class="table table-row-bordered table-custom-padding" @keyup.alt.enter="addItemRowToList" @keyup.alt.46="removeItemRowFromList">
                  <thead>
                  <tr>
                    <th width="250px">{{ $t('items.item_details') }}</th>
                    <th width="140px">{{ $t('sales_invoices.service') }}</th>
                    <th width="300px">
                      <div class="d-flex justify-content-between">
                        <span>{{ $t('sales_invoices.item') }}</span>
                        <a href="javascript:;" class="btn btn-primary btn-sm p-1" @click="showItemModal">
                          <b-icon icon="plus"></b-icon>
                        </a>
                      </div>
                    </th>
                    <th width="140px">{{ $t('sales_invoices.unit_price') }}</th>
                    <th width="140px">{{ $t('sales_invoices.qty') }}</th>
                    <th width="100px">{{ $t('sales_invoices.subtotal') }}</th>
                    <th width="30px"></th>
                  </tr>

                  </thead>
                  <tbody>
                  <tr v-for="(row, index) in items_list" :key="index" :class="getClassValidationRepeaterByIndex(index)">
                    <td>
                      <a :id="'popover-target-'+index" href="javascript:;" v-if="row.item" class="d-flex cursor-pointer">
                        <img :src="row.item.image_url" style="width: 70px; height: 70px;" alt="">
                        <div class="pl-2 pr-2 text-dark">
                          <p class="mb-1" v-if="row.item.sku_code">{{ $t('items.sku_code') }}: {{ row.item.sku_code }}</p>
                          <p class="mb-1" v-if="row.item.name">{{ $t('items.name') }}: {{ row.item.name }}</p>
                          <p class="mb-1" v-if="row.item.service_price">{{ $t('items.sale_price') }}: {{ row.item.service_price }}</p>
                          <p class="mb-1" v-if="row.item.purchase_price">{{ $t('items.purchase_price') }}: {{ row.item.purchase_price }}</p>
                          <p class="mb-1" v-if="row.item.available_stock_qty">{{ $t('items.sum_available_qty') }}: {{ row.item.available_stock_qty }}</p>
                        </div>
                      </a>
                    </td>
                    <td>
                      <select name="" id="service_id" @change="setValue(index)" v-model="row.service_id" class="custom-select">
                        <option v-for="row in services_list" :value="row.id" :key="row.id">{{ row.name }}</option>
                      </select>
                      <span v-if="validation && validation[`items_list.${index}.service_id`]" class="fv-plugins-message-container invalid-feedback">
                            {{ validation[`items_list.${index}.service_id`][0] }}
                          </span>

                    </td>
                    <td>
                      <div class="d-flex search-item-group" v-b-tooltip.hover.leftbottom :title="$t('type_at_least_three_letters') + ' ' + $t('estimations.item')">
                        <select name="" id="" v-model="row.f_by" class="custom-select" style="width: 100px;">
                          <option value="name" selected>{{ $t('items.name') }}</option>
                          <option value="sku_code">{{ $t('items.sku_code') }}</option>
                        </select>
                        <multiselect v-model="row.item"
                                     :placeholder="$t('sales_invoices.item')"
                                     :label="row.f_by? row.f_by:'name'"
                                     track-by="id"
                                     :options="items"
                                     :multiple="false"
                                     @input="selectItem(index)"
                                     :taggable="false"
                                     :show-labels="false"
                                     :show-no-options="false"
                                     :show-no-results="false"
                                     :internal-search="false"
                                     @search-change="getItems(index,row.f_by, $event)">
                        </multiselect>
                      </div>

                    </td>

                    <td>
                      <input v-model="row.unit_price" @input="setValue(index)" type="number" class="form-control" :class="validation && validation[`items_list.${index}.unit_price`] ? 'is-invalid' : ''">
                      <span v-if="validation && validation[`items_list.${index}.unit_price`]" class="fv-plugins-message-container invalid-feedback">
                          {{ validation[`items_list.${index}.unit_price`][0] }}
                        </span>
                    </td>

                    <td>
                      <input v-model="row.qty" @input="setValue(index)" type="number" class="form-control" :class="validation && validation[`items_list.${index}.qty`] ? 'is-invalid' : ''">
                      <span v-if="validation && validation[`items_list.${index}.qty`]" class="fv-plugins-message-container invalid-feedback">
                        {{ validation[`items_list.${index}.qty`][0] }}
                      </span>
                    </td>

                    <td class="text-center">{{ row.subtotal }}</td>
                    <td>
                      <div class="d-flex">
                        <v-icon style="color: #dc3545;" class="p-3" small v-if="items_list.length > 1" @click="removeItemRowFromList(index)">mdi-delete</v-icon>
                        <a href="javascript:;" style="color: #049179;" class="p-3" @click="showMoreDetailsForSubItem(index)"><i class="fas fa-list"></i></a>

                      </div>
                    </td>
                  </tr>
                  </tbody>
                  <tfoot>
                  <tr>
                    <td colspan="3"></td>
                    <th colspan="2">{{ $t('sales_invoices.total_amount_before_discount') }}</th>
                    <th colspan="1"><span>{{ data.total_amount_before_discount ? data.total_amount_before_discount : '0' }} {{ currency_code }}</span></th>
                    <th></th>
                  </tr>
                  <tr>
                    <td class="border-0" colspan="3"></td>
                    <th colspan="2">{{ $t('sales_invoices.total_discount') }}</th>
                    <th colspan="1"><span>{{ data.invoice_total_discount ? data.invoice_total_discount : '0' }} {{ currency_code }}</span></th>
                    <th></th>
                  </tr>
                  <tr>
                    <td class="border-0" colspan="3"></td>
                    <th colspan="2">{{ $t('sales_invoices.total_amount_after_discount') }}</th>
                    <th colspan="1"><span>{{ data.total_amount_after_discount ? data.total_amount_after_discount : '0' }} {{ currency_code }}</span></th>
                    <th></th>
                  </tr>

                  <tr>
                    <td class="border-0" colspan="3"></td>
                    <th colspan="2">{{ $t('sales_invoices.total_after_shipping') }}</th>
                    <th colspan="1"><span>{{ data.shipping_fees ? data.shipping_fees : '0' }} {{ currency_code }}</span></th>
                    <th></th>
                  </tr>
                  <tr>
                    <td class="border-0" colspan="3"></td>
                    <th colspan="2">{{ $t('sales_invoices.total_tax') }}</th>
                    <th colspan="1"><span>{{ data.invoice_total_taxes ? data.invoice_total_taxes : '0' }} {{ currency_code }}</span></th>
                    <th></th>
                  </tr>
                  <tr>
                    <td class="border-0" colspan="3"></td>
                    <th colspan="2">{{ $t('sales_invoices.final_total') }} <i class="fa fa-exclamation-circle ml-2 mr-2" v-b-tooltip.hover.leftbottom :title="$t('sales_invoices.total_amount_before_discount') + ' - ' + $t('sales_invoices.total_discount') + ' + ' + $t('sales_invoices.total_after_shipping') + ' + ' + $t('sales_invoices.total_tax')"></i></th>
                    <th colspan="1"><span>{{ data.invoice_total ? data.invoice_total : '0' }} {{ currency_code }}</span></th>
                    <th></th>
                  </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>

          <div class="mt-5">
            <b-tabs content-class="mt-3" class="nav-custom-link">
              <b-tab :title="$t('discount_and_settlement')">
                <div class="card card-custom">
                  <div class="card-body row">

                    <div class="col-lg-3 mb-5">
                      <label>{{ $t('sales_invoices.tax') }}</label>

                      <multiselect v-model="tax"
                                   :placeholder="$t('sales_invoices.tax')"
                                   label="name"
                                   track-by="id"
                                   :options="taxes"
                                   :multiple="false"
                                   :taggable="false"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false"
                                   @input="setTax"
                                   @search-change="getTaxes($event)">
                      </multiselect>
                      <span v-if="validation && validation.tax_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.tax_id[0] }}
                                    </span>
                    </div>

                    <div class="col-lg-3 mb-5">
                      <label>{{ $t('sales_invoices.discount_type') }}</label>
                      <select name="" id="discount_types" @change="calcItemListForTotal" v-model="data.discount_type" class="custom-select" :class="validation && validation.discount_type ? 'is-invalid' : ''">
                        <option v-for="row in discount_types" :value="row.id" :key="row.id">{{ row.name }}</option>
                      </select>
                      <span v-if="validation && validation.discount_type" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.discount_type[0] }}
                                    </span>
                    </div>
                    <div class="col-lg-3 mb-5">
                      <label>{{ $t('sales_invoices.discount_value') }}</label>
                      <input type="number" v-model="data.discount_value" @input="calcItemListForTotal" class="form-control" :class="validation && validation.discount_value ? 'is-invalid' : ''"/>
                      <span v-if="validation && validation.discount_value" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.discount_value[0] }}
                                    </span>
                    </div>
                    <div class="col-lg-3 mb-5">
                      <label>{{ $t('sales_invoices.adjustment') }}</label>
                      <input type="number" v-model="data.adjustment_value" class="form-control" :class="validation && validation.adjustment_value ? 'is-invalid' : ''"/>
                      <span v-if="validation && validation.adjustment_value" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.adjustment_value[0] }}
                                    </span>
                    </div>
                    <div class="col-lg-3 mb-5">
                      <label>{{ $t('sales_invoices.deposite_value') }}</label>
                      <input type="number" v-model="data.deposite_value" class="form-control" :class="validation && validation.deposite_value ? 'is-invalid' : ''"/>
                      <span v-if="validation && validation.deposite_value" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.deposite_value[0] }}
                                    </span>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab :title="$t('delivery_information')">
                <div class="card card-custom">
                  <div class="card-body row">
                    <div class="col-lg-3 mb-5">
                      <label>{{ $t('sales_invoices.shipping_details') }}</label>
                      <select name="" id="shipping_details" v-model="data.shipping_details" class="custom-select" :class="validation && validation.shipping_details ? 'is-invalid' : ''">
                        <option v-for="row in shipping_details_list" :value="row.id" :key="row.id">{{ row.title }}</option>
                      </select>
                      <span v-if="validation && validation.shipping_details" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.shipping_details[0] }}
                                    </span>
                    </div>
                    <div class="col-lg-3 mb-5">
                      <label>{{ $t('sales_invoices.shipping_fees') }}</label>
                      <input type="number" v-model="data.shipping_fees" @input="calcItemListForTotal" class="form-control" :class="validation && validation.shipping_fees ? 'is-invalid' : ''"/>
                      <span v-if="validation && validation.shipping_fees" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.shipping_fees[0] }}
                                    </span>
                    </div>

                  </div>
                </div>
              </b-tab>
              <b-tab :title="$t('deposit')">
                <div class="card card-custom">
                  <div class="card-body row">
                  </div>
                </div>
              </b-tab>

              <b-tab :title="$t('current_payment')" :disabled="(data.is_paid == 1 || data.is_paid == 2) && isEditing">
                <div class="card card-custom">
                  <div class="card-body row">

                    <div class="col-lg-12 mb-5">
                      <b-form-checkbox id="checkbox-paid" v-model="data.initially_paid" name="checkbox-paid" :value="1">
                        {{ $t('already_paid') }}
                      </b-form-checkbox>
                    </div>

                    <div class="col-lg-3 mb-5">
                      <label>{{ $t('payment_sales_invoices.payment_method') }}</label>
                      <select name="" id="payment_method" v-model="data.initial_payment_method" @change="onPaymentMethodChanged(data.initial_payment_method)" class="custom-select" :class="validation && validation.initial_payment_method ? 'is-invalid' : ''">
                        <option v-for="row in payment_methods" :value="row.id" :key="row.id" :disabled="row.id==0">{{ row.title }}</option>
                      </select>
                      <span v-if="validation && validation.initial_payment_method" class="fv-plugins-message-container invalid-feedback">
                            {{ validation.initial_payment_method[0] }}
                        </span>
                    </div>

                    <div class="col-lg-3 mb-5" v-if="initial_payment_method == 2 || initial_payment_method == 3">
                      <label>{{ $t('payment_sales_invoices.banks') }}<span v-if="data.initially_paid == 1" class="text-danger">*</span></label>
                      <div class="input-group">
                        <multiselect v-model="bank"
                                     :class="validation && validation.initial_payment_method_type_id ? 'is-invalid' : ''"
                                     :placeholder="$t('payment_sales_invoices.banks')"
                                     label="name"
                                     track-by="id"
                                     :options="banks"
                                     :multiple="false"
                                     :taggable="false"
                                     :show-labels="false"
                                     :show-no-options="false"
                                     :show-no-results="false"
                                     @search-change="getBanks($event)">
                        </multiselect>
                        <div class="input-group-prepend">
                          <a class="btn btn-primary" href="/finances/banks/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                        </div>
                      </div>
                      <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('payment_sales_invoices.banks') }}.</span>
                      <span v-if="validation && validation.initial_payment_method_type_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.initial_payment_method_type_id[0] }}
                            </span>
                    </div>

                    <div class="col-lg-3 mb-5" v-else>
                      <label>{{ $t('payment_sales_invoices.treasuries') }}<span v-if="data.initially_paid == 1" class="text-danger">*</span></label>
                      <div class="input-group">
                        <multiselect v-model="treasury"
                                     :class="validation && validation.initial_payment_method_type_id ? 'is-invalid' : ''"
                                     :placeholder="$t('payment_sales_invoices.treasuries')"
                                     label="name"
                                     track-by="id"
                                     :options="treasuries"
                                     :multiple="false"
                                     :taggable="false"
                                     :show-labels="false"
                                     :show-no-options="false"
                                     :show-no-results="false">
                        </multiselect>
                        <div class="input-group-prepend">
                          <a class="btn btn-primary" href="/finances/treasuries/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                        </div>
                      </div>
                      <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('payment_sales_invoices.treasuries') }}.</span>
                      <span v-if="validation && validation.initial_payment_method_type_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.initial_payment_method_type_id[0] }}
                            </span>
                    </div>

                    <div class="col-lg-3 mb-5">
                      <label>{{ $t('payment_sales_invoices.amount') }}<span v-if="data.initially_paid == 1" class="text-danger">*</span></label>
                      <input type="number" v-model="data.initial_payment_amount" class="form-control" :class="validation && validation.initial_payment_amount ? 'is-invalid' : ''"/>
                      <span v-if="validation && validation.initial_payment_amount" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.initial_payment_amount[0] }}
                            </span>
                    </div>

                  </div>
                </div>
              </b-tab>
              <b-tab :title="$t('notes')">
                <div class="card card-custom">
                  <div class="card-body row">
                    <div class="col-lg-12 mb-5">
                      <label>{{ $t('sales_invoices.notes') }}</label>
                      <textarea name="" id="notes" v-model="data.notes" class="form-control" :class="validation && validation.notes ? 'is-invalid' : ''"></textarea>
                      <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.notes[0] }}
                            </span>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>


          </div>

        </b-tab>

        <b-tab :title="$t('sales_invoices.attachments')" :disabled="!idEditing" @click="toggleTab('attachments')">
          <div class="card card-custom mt-5">
            <div class="card-body">
              <attachments :sales-invoice-id="idEditing"></attachments>
            </div>
          </div>
        </b-tab>
        <b-tab v-if="idEditing" :title="$t('activity_log.activity_log')" @click="toggleTab('activity_log')">
          <div class="card card-custom mt-5">
            <div class="card-body">
              <br>
              <activity-log :inner-key="key" :id="idEditing"></activity-log>
            </div>
          </div>
        </b-tab>
        <b-tab v-if="idEditing" :title="$t('stock_change_control_log')" @click="toggleTab('statistics_log_inventory')">
          <div class="card card-custom mt-5">
            <div class="card-body">
              <br>
              <statistics-log-inventory :inner-key="key" :id="idEditing"></statistics-log-inventory>
            </div>
          </div>
        </b-tab>
      </b-tabs>


      <!--        </div>-->
      <div class="pl-0 pr-0" v-if="tab_name != 'activity_log' && tab_name != 'statistics_log_inventory'">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="reset" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
            <button type="reset" class="btn btn-secondary">{{ $t('cancel') }}</button>
          </div>
        </div>
      </div>
    </div>
    <!--end::customer-->
    <b-modal ref="modal" size="lg" hide-footer :title="$t('customers.add_new_customer')">
      <customer-form @hide-modal="hideModal()" @handling-data="getDataAfterCreateNewCustomer"></customer-form>
    </b-modal>
    <b-modal ref="modalItem" size="lg" hide-footer :title="$t('items.add_new_item')">
      <item-form @hide-modal="hideItemModal()" @handling-data="getDataAfterCreateNewItem"></item-form>
    </b-modal>
    <b-modal ref="modalCustomerAddress" size="lg" hide-footer :title="$t('add_new_customer_address')">
      <customer-address-info @hide-modal="hideCustomerAddressModal()" @handling-data="getDataAfterCreateNewCustomerAddress" :customer-id="data.customer_id"></customer-address-info>
    </b-modal>
    <b-modal ref="modalMoreSubItems" size="xl" hide-footer :title="$t('point_of_sales.edit_item_notes')">
      <div>
        <div class="table-responsive">
          <table class="table border-0">
            <thead style="background-color: transparent;">
            <tr>
              <th>{{ $t('sales_invoices.item') }}</th>
              <th>{{ $t('point_of_sales.color') }}</th>
              <th>{{ $t('point_of_sales.damage') }}</th>
              <th>{{ $t('point_of_sales.stains') }}</th>
              <th>{{ $t('point_of_sales.upcharge') }}</th>
              <th>{{ $t('point_of_sales.notes') }}</th>
              <th></th>
            </tr>
            </thead>
            <tbody>

            <template v-if="items_list[index_item_selected]">
              <tr v-for="(row, index) in toIntegerVal(items_list[index_item_selected].qty)" :key="'more-details-for-item'+index">
                <td><b>{{ items_list[index_item_selected].item ? items_list[index_item_selected].item.name : '' }}</b></td>
                <td>
                  <template v-if="items_list[index_item_selected].colors[index]" v-for="(ll_row, ll_index) in items_list[index_item_selected].colors[index].length">
                    <select class="custom-select d-block" v-model="items_list[index_item_selected].colors[index][ll_index]">
                      <option v-for="(cc_row, cc_index) in colors_list" :value="cc_row.id" :key="'cc_index'+cc_index">{{ cc_row.name }}</option>
                    </select>
                  </template>
                </td>
                <td>
                  <template v-if="items_list[index_item_selected].damages[index]" v-for="(ll_row, ll_index) in items_list[index_item_selected].damages[index].length">
                    <select class="custom-select d-block" v-model="items_list[index_item_selected].damages[index][ll_index]">
                      <option v-for="(at_row, at_index) in damage_list" :value="at_row.id" :key="'at_index'+at_index">{{ at_row.name }}</option>
                    </select>
                  </template>
                </td>
                <td>
                  <template v-if="items_list[index_item_selected].stains[index]" v-for="(ll_row, ll_index) in items_list[index_item_selected].stains[index].length">
                    <select class="custom-select d-block" v-model="items_list[index_item_selected].stains[index][ll_index]">
                      <option v-for="(at_row, at_index) in stains_list" :value="at_row.id" :key="'at_index'+at_index">{{ at_row.name }}</option>
                    </select>

                  </template>
                </td>
                <td></td>
                <td>
                  <input type="text" class="form-control" v-model="items_list[index_item_selected].notes[index]">
                </td>
                <td><a href="javascript:;" class="text-primary" @click="copyAttributes(index_item_selected, index)"><i class="fas fa-copy"></i></a></td>
              </tr>

            </template>

            </tbody>
          </table>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Attachments from "./Attachments";
import ItemForm from "@/view/content/forms/ItemForm";
import CustomerForm from "@/view/content/forms/CustomerForm";
import ActivityLog from "@/view/content/widgets/details/ActivityLog";
import StatisticsLogInventory from "@/view/content/widgets/details/StatisticsLogInventory";
import {mapGetters, mapState} from "vuex";
import Vue from "vue";
import CustomerAddressInfo from "@/view/content/forms/CustomerAddressInfo.vue";
import timeZoneStructure from "@/core/config/mix/timeZoneStructure";

export default {
  name: "form-sales_invoices",
  components: {'attachments': Attachments, 'item-form': ItemForm, 'customer-form': CustomerForm, 'activity-log': ActivityLog, 'statistics-log-inventory': StatisticsLogInventory, 'customer-address-info': CustomerAddressInfo},
  data() {
    return {
      mainRoute: 'sales/sales_laundries',
      mainRouteDependency: 'base/dependency',
      mainRouteForSalesQuotations: 'sales/sales_quotations',
      mainTaxSettingsRoute: 'sales/tax_setting_sales',
      data: {
        invoice_code: null,
        invoice_subtotal: 0,
        invoice_total: 0,
        invoice_total_discount: 0,
        invoice_total_shipping: 0,
        invoice_total_taxes: 0,
        total_amount_before_discount: 0,
        total_amount_after_discount: 0,
        shipping_details: 1,
        shipping_fees: null,
        // adjustment: null,
        customer_id: null,
        discount_type: 1,
        discount_value: null,
        currency_id: null,
        status: 1,
        invoice_date: null,
        notes: null,
        adjustment_value: null,
        deposite_value: null,
        payment_terms: null,
        issue_date: null,
        inventory_id: null,
        account_id: null,
        // branch_id: null,
        // exchange_rate: null,
        price_list_id: null,
        reference_number: null,
        apply_tax_rules: [],

        initial_payment_method: 1,
        initial_payment_amount: null,
        initial_payment_method_type_type: null,
        initial_payment_method_type_id: null,
        initially_paid: 0,
        is_paid: null,
        tax_id: null,
        received_date: null,
        received_time: null,
        delivery_date: null,
        delivery_time: null,
        is_apply_taxes_invoice: false,
      },

      key: 'SalesInvoice',
      idEditing: this.$route.params.id ? this.$route.params.id : null,
      isEditing: false,
      idClone: this.$route.params.clone_id ? this.$route.params.clone_id : null,
      isDisabled: false,
      customers: [],
      currencies: [],
      inventories: [],
      inventory: null,
      items: [],
      taxes: [],
      units: [],
      shipping_details_list: [],
      status_list: [],
      accounts: [],
      account: null,
      // branch: null,
      // branches: [],
      // shipping_details_list: [
      // {id: 0, name: this.$t('shipping_details.auto')},
      // {id: 1, name: this.$t('shipping_details.dont_show')},
      // {id: 2, name: this.$t('shipping_details.show_main_client_details')},
      // {id: 3, name: this.$t('shipping_details.show_secondary_client_details')},
      // ],
      validation: null,
      tax_default: null,
      unit_default: null,
      customer: null,

      items_list_form: {f_by: 'name', id: null, item: null, unit_price: null, qty: null, service_id: null, subtotal: null, colors: [[null, null, null]], damages: [[null, null, null]], stains: [[null, null, null]], additional_cost: [[null, null, null]], notes: ['']},
      items_list: [],
      // discount_types: [
      //   {id: 1, name: this.$t('discount_types.none')},
      //   {id: 2, name: this.$t('discount_types.percentage')},
      //   {id: 3, name: this.$t('discount_types.value')},
      // ],
      discount_types: [
        {id: 1, name: '-'},
        {id: 2, name: '%'},
        {id: 3, name: this.$t('discount_types.value')},
      ],
      tab_name: 'basic_information',
      PlanMessage: null,
      repeater_validation: [],
      price_list: null,
      pricesLists: [],
      prices_for_repeater: [],

      sales_request_id: this.$route.params.quotation_requests_id ? this.$route.params.quotation_requests_id : null,
      quotation_type: this.$route.params.quotation_type ? this.$route.params.quotation_type : null,

      tax_settings: [],
      currency_code: null,

      customer_address: null,
      customer_addresses: [],
      taxes_rules_for_tax_setting: [],


      payment_methods: [],
      treasuries: [],
      treasury: null,
      banks: [],
      bank: null,
      initial_payment_method: 1,

      damage_list: [],
      stains_list: [],
      services_list: [],
      colors_list: [],

      tax: null,
      index_item_selected: null,

      is_company_has_tax_no: false,
    };
  },
  computed: {
    ...mapState({user_personal_info: state => state.profile.user_personal_info}),
    ...mapGetters(["currentUserPersonalInfo"]),

  },
  watch: {

    account: function (val) {
      if (val) {
        this.data.account_id = val.id;
      }
    },
    // items_list: {
    //   handler(val) {
    //     if (val.length > 0) {
    //       this.calcItemListForTotal();
    //     }
    //   },
    //   deep: true
    // },
    '$route'(val) {
      if (val.params.id) {
        this.idEditing = val.params.id;
        this.getData();
      }
    },
    customer: function (val) {
      if (val) {
        this.data.customer_id = val.id;
        this.getCustomerAddresses();
      } else {
        this.data.customer_id = null;
      }
    },
    'data.customer_id': function (val) {
      if (val) {
        let _cu = this.customers.filter(row => row.id == val);
        if (_cu[0]) {

          _cu = _cu[0]
          if (_cu.currency_id) {
            this.data.currency_id = _cu.currency_id;
          } else {
            this.getDefaultCurrency();
          }
        }
      }
    },
    inventory: function (val) {
      if (val) {
        this.data.inventory_id = val.id;
        this.getQtyDependentOnInventory();
      } else {
        this.data.inventory_id = null;
      }
    },
    // branch: function (val) {
    //   if (val) {
    //     this.data.branch_id = val.id;
    //   } else {
    //     this.data.branch_id = null;
    //   }
    // },
    price_list: function (val) {
      if (val) {
        this.data.price_list_id = val.id;
      } else {
        this.data.price_list_id = null;
      }
    },
    "data.currency_id": function (val) {
      if (val) {
        let _currency = this.currencies.find((row) => row.id == val);
        if (_currency) {
          this.currency_code = _currency.code;
        } else {
          this.currency_code = null;
        }
      } else {
        this.currency_code = null;
      }
      // if (val) {
      //   this.getExchangeRate(val);
      // } else {
      //   this.data.exchange_rate = null;
      // }

    },

    /**
     * payments watches
     * @param val
     */
    "data.payment_method": function (val) {
      if (val) {
        this.payment_method = val;
      }
    },
    treasury: function (val) {
      if (val && val.id) {
        this.data.initial_payment_method_type_type = 'Treasuries';
        this.data.initial_payment_method_type_id = val.id;
      }
    },
    bank: function (val) {
      if (val && val.id) {
        this.data.initial_payment_method_type_type = 'Bank';
        this.data.initial_payment_method_type_id = val.id;
      }
    },
  },
  methods: {
    ...timeZoneStructure,
    readDataFromExcel(event) {
      if (event.length > 0) {
        let _sku_codes = [];
        let _data = [];
        event.forEach((row) => {
          let _sku_code = row.sku_code;

          if (_sku_code) {
            _sku_codes.push(_sku_code);
          }
          let _quantity = (row.quantity ? parseInt(row.quantity) : 0);
          _quantity = (!isNaN(_quantity) ? _quantity : 0);
          let _price = (row.price ? parseFloat(row.price) : 0);
          _price = (!isNaN(_price) ? _price : 0);

          let _subtotal = _quantity * _price;

          _data.push({f_by: 'sku_code', id: null, item: null, sku_code: row.sku_code, description: null, unit_price: _price, qty: _quantity, unit: null, tax: null, tax_2: null, discount_type: 1, discount_value: null, subtotal: _subtotal, tax_percentage: null, units_number: null, tax_applied: []})
        });

        this.getItemsByIds(_sku_codes).then((response) => {

          this.items_list = _data.filter((row) => {
            row.item = response.find((da) => da.sku_code == row.sku_code);

            return row.item != null;
          });

          this.items_list.forEach((row, index) => {
            this.setValue(index);
          });
        });
      }

    },
    async getItemsByIds(sku_codes = []) {
      if (sku_codes && sku_codes.length > 0)
        return await ApiService.get(this.mainRouteDependency + "/itemsByIds", {params: {sku_codes: sku_codes}}).then((response) => {
          return response.data.data;
        });
    },
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create(params = []) {
      ApiService.post(`${this.mainRoute}`, {
        items_list: this.items_list,
        ...this.data,
        ...params,
      })
          .then((response) => {
            this.$router.push({name: 'sales_laundries.edit', params: {id: response.data.data.id}});
            this.validation = null;
            this.$successAlert(response.data.message);
          })
          .catch((error) => {
            if (error.response.data.status_code == 423) {
              this.repeater_validation = error.response ? error.response.data.errors : [];
              if (this.data.status == 2 || this.data.status == 5) {
                this.$errorAlert(error);
              } else {
                Vue.prototype.$postStatus = true;
                this.$confirmAlert(
                    error.response.data.message ? error.response.data.message : '',
                    this.create,
                    {action: 'confirm'}
                );
              }

            } else {
              this.$errorAlert(error);
              this.validation = error.response ? error.response.data.errors : null;

            }
            Vue.prototype.$postStatus = true;
          });
    },


    update(params = []) {
      ApiService.put(`${this.mainRoute}/${this.$route.params.id}`, {
        items_list: this.items_list,
        ...this.data,
        ...params,

      })
          .then((response) => {
            this.$router.push({name: 'sales_laundries.index'});
            this.validation = null;
            this.$successAlert(response.data.message);
          })
          .catch((error) => {
            if (error.response.data.status_code == 423) {
              this.repeater_validation = error.response ? error.response.data.errors : [];
              if (this.data.status == 2 || this.data.status == 5) {
                this.$errorAlert(error);
              } else {
                Vue.prototype.$postStatus = true;
                this.$confirmAlert(
                    error.response.data.message ? error.response.data.message : '',
                    this.update,
                    {action: 'confirm'}
                );
              }
            } else {
              this.$errorAlert(error);
              this.validation = error.response ? error.response.data.errors : null;

            }

            Vue.prototype.$postStatus = true;
          });
    },

    getClassValidationRepeaterByIndex(index) {
      if (index != null) {
        if (this.repeater_validation.includes(index))
          return 'tr-validation-error';
      }
      return '';
    },
    async getData() {
      let _id = (this.idEditing ? this.idEditing : (this.idClone ? this.idClone : null));
      if (_id)
        await ApiService.get(`${this.mainRoute}/${_id}`).then((response) => {

          this.data.invoice_subtotal = response.data.data.invoice_subtotal;
          this.data.invoice_total = response.data.data.invoice_total;
          this.data.invoice_total_discount = response.data.data.invoice_total_discount;
          this.data.invoice_total_shipping = response.data.data.invoice_total_shipping;
          this.data.invoice_total_taxes = response.data.data.invoice_total_taxes;

          this.data.total_amount_before_discount = response.data.data.total_amount_before_discount;
          this.data.total_amount_after_discount = response.data.data.total_amount_after_discount;

          this.data.shipping_details = response.data.data.shipping_details;
          this.data.shipping_fees = response.data.data.shipping_fees;
          this.data.adjustment_value = response.data.data.adjustment_value;
          this.data.customer_id = response.data.data.customer_id;
          this.data.discount_type = response.data.data.discount_type;
          this.data.discount_value = response.data.data.discount_value;
          this.data.currency_id = response.data.data.currency_id;
          this.data.status = response.data.data.status;
          this.data.invoice_date = response.data.data.invoice_date;
          this.data.notes = response.data.data.notes;
          this.data.issue_date = response.data.data.issue_date;
          this.data.deposite_value = response.data.data.deposite_value;
          this.data.payment_terms = response.data.data.payment_terms;
          this.data.account_id = response.data.data.account_id;
          this.data.tax_id = response.data.data.tax_id;
          this.customer = response.data.data.customer;
          // this.data.exchange_rate = response.data.data.exchange_rate;
          this.data.price_list_id = response.data.data.price_list_id;
          this.price_list = response.data.data.price_list;
          this.items_list = response.data.data.items_list;
          this.account = response.data.data.account;
          // this.data.branch_id = response.data.data.branch_id;
          // this.branch = response.data.data.branch;
          this.data.reference_number = response.data.data.reference_number;
          this.data.apply_tax_rules = response.data.data.apply_tax_rules;

          this.data.received_date = response.data.data.received_date;
          this.data.received_time = response.data.data.received_time;
          this.data.delivery_date = response.data.data.delivery_date;
          this.data.delivery_time = response.data.data.delivery_time;

          this.data.initial_payment_method = response.data.data.initial_payment_method;
          this.data.initial_payment_amount = response.data.data.initial_payment_amount;
          this.data.treasury_id = response.data.data.treasury_id;
          this.data.initial_payment_method_type_type = response.data.data.initial_payment_method_type_type;
          this.data.initial_payment_method_type_id = response.data.data.initial_payment_method_type_id;
          this.data.initially_paid = response.data.data.initially_paid;
          this.data.is_paid = response.data.data.is_paid;
          this.data.is_apply_taxes_invoice = response.data.data.is_apply_taxes_invoice;

          this.bank = response.data.data.bank;
          this.treasury = response.data.data.treasury;
          this.tax = response.data.data.tax;

          if (!response.data.data.account_id) {
            this.getDefaultAccount();
          }

          this.data.inventory_id = response.data.data.inventory_id;
          this.inventory = response.data.data.inventory;
          if (response.data.data.items_list && response.data.data.items_list.length <= 0) {
            this.addItemRowToList();
          }
          if (this.idEditing) {
            this.isEditing = true;
            this.data.invoice_code = response.data.data.invoice_code;
          }
        });
    },

    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    getInventories() {
      ApiService.get(this.mainRouteDependency + "/inventories").then((response) => {
        this.inventories = response.data.data;
        if (!this.idEditing) {
          this.inventory = this.inventories.find(option => option.is_default == 1);
        }

      });
    },
    // getBranches() {
    //   ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
    //     this.branches = response.data.data;
    //     if (!this.idEditing) {
    //       this.branch = this.branches.find(option => option.is_default == 1);
    //     }
    //
    //   });
    // },
    async getCustomers(filter) {
      if (filter && filter.length >= 3) {
        await ApiService.get(`${this.mainRouteDependency}/customers`, {params: {filter: filter}}).then((response) => {
          this.customers = response.data.data;
        });
      } else {
        this.customers = [];
      }
    },
    getItems(index = 0, f_by = null, filter = null) {
      let _inventory_id = this.data.inventory_id;
      let _service_id = this.items_list[index].service_id;

      if (filter && filter.length >= 3 && !_inventory_id) {
        this.$errorAlertMessage(this.$t('select_inventory_to_get_qty'));
        return false;
      }
      let _f_by = f_by ? f_by : 'name';
      if (filter && filter.length >= 3 && _f_by)
        ApiService.get(this.mainRouteDependency + "/items_laundry_service/" + _service_id, {params: {[_f_by]: filter, inventory_id: _inventory_id}}).then((response) => {
          this.items = response.data.data;
        });
    },
    getTaxes(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/taxes?type=4`, {params: {filter: filter}}).then((response) => {
          this.taxes = response.data.data;
        });
      } else {
        this.taxes = [];
      }
    },


    // async getDefaultTax() {
    //   await ApiService.get(`${this.mainRouteDependency}/default-tax`).then((response) => {
    //     this.tax_default = response.data.data;
    //   });
    // },
    async getDefaultUnit() {
      await ApiService.get(`${this.mainRouteDependency}/default-unit`).then((response) => {
        this.unit_default = response.data.data;
      });
    },

    getShippingDetails() {
      ApiService.get(this.mainRouteDependency + "/shipping_details").then((response) => {
        this.shipping_details_list = response.data.data;
      });
    },

    getCustomerAddresses() {
      if (this.data.customer_id)
        ApiService.get(this.mainRouteDependency + "/customer_addresses/" + this.data.customer_id).then((response) => {
          this.customer_addresses = response.data.data;
          this.customer_address = response.data.data.find(row => row.is_default == true);
        });
    },

    getPricesLists() {
      ApiService.get(this.mainRouteDependency + "/pricesLists").then((response) => {
        this.pricesLists = response.data.data;
      });
    },
    getStatus() {
      ApiService.get(this.mainRouteDependency + "/sales_status", {params: {type: 'sales_invoices'}}).then((response) => {
        this.status_list = response.data.data;
      });
    },

    loadOptions() {
    },
    updateValue(value) {
      this.data.account_id = value;
    },

    addItemRowToList() {
      this.items_list.unshift(this.items_list_form);
      this.items_list_form = {f_by: 'name', id: null, item: null, unit_price: null, qty: null, service_id: null, subtotal: null, colors: [[null, null, null]], damages: [[null, null, null]], stains: [[null, null, null]], additional_cost: [[null, null, null]], notes: ['']};
      this.reindexingRepeaterValidation();
    },
    removeItemRowFromList(index = 0) {
      if (this.items_list.length > 1) {
        this.items_list.splice(index, 1);
        this.repeater_validation = this.repeater_validation.filter((val) => {
          return index != val;
        });
        this.reindexingRepeaterValidation();
      }
    },

    reindexingRepeaterValidation() {
      if (this.repeater_validation.length > 0) {
        this.repeater_validation = this.repeater_validation.map((val) => {
          return val + 1;
        });
      }
    },
    selectItem(index) {
      let _price = this.prices_for_repeater.find(row => row.item_id == this.items_list[index].item.id);

      if (_price) {
        this.items_list[index].unit_price = _price.unit_price
      } else {
        this.items_list[index].unit_price = this.items_list[index].item ? this.items_list[index].item.unit_price : 0;
      }
      this.items_list[index].tax_percentage = this.items_list[index].item ? this.items_list[index].item.total_taxes : 0;
      this.items_list[index].tax_applied = (this.items_list[index].item && this.items_list[index].item.taxes) ? this.items_list[index].item.taxes : [];
      this.setValue(index);

      /** get available qty dependent on inventory **/
      // let _item_id = this.items_list[index].item ? this.items_list[index].item.id : null;
      // if (_item_id)
      //   this.getQtyDependentOnInventory(_item_id).then((response)=>{
      //     this.items_list[index].item.sum_available_qty = response;
      //   });

    },
    setValueUnit(index) {
      this.items_list[index].qty = 0;
      this.setValue(index);
    },
    setValue(index) {
      this.zeroForNegative();
      this.addMoreOrLessQTY(index);
      let _tax = 0;
      let _tax_include_tax = 0;
      // if (this.items_list[index].unit) {
      //   if (this.items_list[index].unit.parent_id) {
      //     this.items_list[index].qty = (this.items_list[index].unit.sub_value_total ? this.items_list[index].unit.sub_value_total : 0) * (this.items_list[index].units_number ? this.items_list[index].units_number : 0);
      //   } else {
      //     this.items_list[index].units_number = 0;
      //   }
      // }

      // let _subtotal = (this.items_list[index].unit_price ? this.items_list[index].unit_price : 0) * (this.items_list[index].qty ? this.items_list[index].qty : 0);
      // this.items_list[index].subtotal_before_tax = (_subtotal).toFixed(2);


      // let _discount = 0;
      // if (this.items_list[index].discount_type == 1 || !this.items_list[index].discount_type) {
      //   _discount = 0;
      // } else {
      //   _discount = ((this.items_list[index].discount_type == 2 && this.items_list[index].discount_value) ? (parseFloat(this.items_list[index].discount_value) / 100) * _subtotal : (this.items_list[index].discount_value ? parseFloat(this.items_list[index].discount_value) : 0));
      // }

      /**
       *
       * calc data subtotal before discount  = unit price * quantity
       * calc data subtotal after discount  = (unit price * quantity) - discount
       */
      // this.items_list[index].subtotal_before_discount = _subtotal;
      // this.items_list[index].subtotal_after_discount = _subtotal - _discount;

      /**
       * calculation for taxes
       */


      // this is used to get tax setting value
      // let _tax_rule_value = null;
      // let _tax_rule_id = (this.data.apply_tax_rules && this.data.apply_tax_rules[0]) ? this.data.apply_tax_rules[0].tax_rule_id : null;
      // if (_tax_rule_id) {
      //   let _tax_rule = this.taxes_rules_for_tax_setting.find((row) => row.id == _tax_rule_id);
      //   let _tax_rule_details = _tax_rule.tax_rule_details[0];
      //   _tax_rule_value = _tax_rule_details ? _tax_rule_details.value : null;
      // }

      // dependant on value calculate the tax
      // if (this.tax_settings && this.items_list[index].tax_applied.length) {
      //
      //
      //   let _tax_percentage_include_tax = this.items_list[index].tax_applied[0] ? this.items_list[index].tax_applied[0].include_tax : 0;
      //
      //
      //   let _tax_percentage_first = this.items_list[index].tax_applied[0] ? this.items_list[index].tax_applied[0].percentage : 0;
      //   let _tax_first = 0;
      //   if (_tax_percentage_include_tax == 0){
      //     _tax_first = this.items_list[index].unit_price - (this.items_list[index].unit_price / (1 + (_tax_percentage_first / 100)));
      //     _tax += _tax += (_tax_first * (this.items_list[index].qty ? this.items_list[index].qty : 1));
      //   }else {
      //     if (_tax_rule_value == 1) {
      //       _tax_first = _subtotal * (_tax_percentage_first / 100);
      //       _tax += _tax_first;
      //     } else if (_tax_rule_value == 2) {
      //       _tax_first = (_subtotal - _discount) * (_tax_percentage_first / 100);
      //       _tax += _tax_first;
      //     }
      //   }
      //
      //   _subtotal = _subtotal - _tax_first;
      // }


      // _tax = _subtotal * (this.items_list[index].tax_percentage ? parseFloat(this.items_list[index].tax_percentage) / 100 : 0);


      // this.items_list[index].subtotal_before_tax = (_subtotal).toFixed(2);
      // this.items_list[index].amount_tax = (_tax).toFixed(2);
      // this.items_list[index].subtotal = (parseFloat(this.items_list[index].subtotal_before_tax) + _tax).toFixed(2);
      this.items_list[index].subtotal = (this.items_list[index].unit_price ? this.items_list[index].unit_price : 0) * (this.items_list[index].qty ? this.items_list[index].qty : 0);

      this.calcItemListForTotal();
    },

    zeroForNegative() {
      this.items_list = this.items_list.map((row) => {
        if (isNaN(row.unit_price) || !Number(row.unit_price)) {
          row.unit_price = 1;
        } else if (parseFloat(row.unit_price) < 1) {
          row.unit_price = 1;
        }
        if (isNaN(row.qty) || !Number(row.qty)) {
          row.qty = 1;
        } else if (parseInt(row.qty) < 1) {
          row.qty = 1;
        }

        if (isNaN(row.units_number) || !Number(row.units_number)) {
          row.units_number = 0;
        } else if (parseInt(row.units_number) < 0) {
          row.units_number = 10;
        }

        if (isNaN(row.discount_value) || !Number(row.discount_value)) {
          row.discount_value = 0;
        }
        if (parseFloat(row.discount_value) < 0) {
          row.discount_value = 0;
        }
        if (isNaN(row.tax_percentage) || !Number(row.tax_percentage)) {
          row.tax_percentage = 0;
        }
        if (parseFloat(row.tax_percentage) < 0) {
          row.tax_percentage = 0;
        }
        return row;

      });
    },
    showModal() {
      this.$refs['modal'].show()
    },
    hideModal() {
      this.$refs['modal'].hide();
    },
    showItemModal() {
      this.$refs['modalItem'].show()
    },
    hideItemModal() {
      this.$refs['modalItem'].hide();
    },
    showCustomerAddressModal() {
      this.$refs['modalCustomerAddress'].show()
    },
    hideCustomerAddressModal() {
      this.$refs['modalCustomerAddress'].hide();
    },
    showMoreDetailsForSubItem(index) {
      this.$refs['modalMoreSubItems'].show();
      this.index_item_selected = index;
    },
    hideMoreDetailsForSubItem() {
      this.$refs['modalMoreSubItems'].hide();
      this.index_item_selected = null;
    },
    getDataAfterCreateNewCustomer(object) {
      let promise = this.getCustomers();
      Promise.all([promise]).then(() => {
        this.data.customer_id = object.id;
      })
    },
    getDataAfterCreateNewItem(object) {
      let promise = this.getItems();
      Promise.all([promise]).then(() => {
        if (object) {
          this.items_list[0].item = object;
          this.items_list[0].unit_price = object.unit_price;
          this.items_list[0].tax_percentage = object.total_taxes ? object.total_taxes : 0;
          // this.items_list[0].tax = (object.taxes && object.taxes[0] && object.taxes[0].tax) ? object.taxes[0].tax : null;
          // this.items_list[0].tax_2 = (object.taxes && object.taxes[0] && object.taxes[0].tax) ? object.taxes[0].tax : null;
        }
      })
    },
    getDataAfterCreateNewCustomerAddress(object) {
      let promise = this.getCustomerAddresses();
      Promise.all([promise]).then(() => {
        if (object) {
          this.customer_address = object;
        }
      })
    },
    toggleTab(tab_name) {
      this.tab_name = tab_name
    },
    getCode() {
      ApiService.get(this.mainRouteDependency + "/code-setting/4").then((response) => {
        this.data.invoice_code = response.data.data.code;
      });
    },
    getDefaultCurrency() {
      ApiService.get(this.mainRouteDependency + "/default-currency").then((response) => {
        this.data.currency_id = response.data.data.id;
      });
    },
    // getAccounts() {
    //     ApiService.get(this.mainRouteDependency + `/account_parents`,{params:{checkDisabled: 1,key:'sales'}}).then((response) => {
    //         this.accounts = response.data.data;
    //     });
    // },
    getAccounts(filter) {
      if (filter && filter.length >= 3)
        return ApiService.get(this.mainRouteDependency + `/account_parents_leaf_nodes`, {params: {name: filter}}).then((response) => {
          this.accounts = response.data.data;
        });
    },
    getDefaultAccount() {
      ApiService.get(`${this.mainRouteDependency}/get_default_account/sales`).then((response) => {
        this.account = response.data.data;
      });
    },
    // getExchangeRate(id) {
    //   ApiService.get(`${this.mainRouteDependency}/exchange_rate/` + id).then((response) => {
    //     this.data.exchange_rate = response.data.data.initial_val;
    //   });
    // },
    getPlanMessage() {
      ApiService.get(this.mainRouteDependency + `/check_limits/sales_invoice`).then((response) => {
        this.PlanMessage = response.data.data;
      });
    },
    getQtyDependentOnInventory() {
      let _inventory_id = this.data.inventory_id;
      let _items_ids = [];
      this.items_list.forEach((row) => {
        if (row.item && row.item.id)
          _items_ids.push(row.item.id)
      });
      if (_items_ids.length > 0 && _inventory_id) {
        ApiService.query(this.mainRouteDependency + `/check_available_quantity/${_inventory_id}`, {items_ids: _items_ids}).then((response) => {
          this.items_list = this.items_list.map((row) => {
            let _item = response.data.data.find(it => it.item_id == row.item.id)
            if (_item && row.item)
              row.item.available_stock_qty = _item.available_stock_qty;

            return row;
          });

        });
      } else {
        return 0;
      }

    },

    getAndSetPriceListToRepeater(event) {
      if (event.id)
        ApiService.get(this.mainRouteDependency + `/price_list/${event.id}`).then((response) => {
          this.prices_for_repeater = response.data.data;
          this.items_list = this.items_list.map((row) => {
            if (row.item) {
              let _item = response.data.data.find(it => it.item_id == row.item.id);
              if (_item && row.item)
                row.unit_price = _item.unit_price;
            }
            return row;
          });

        });
    },
    /**
     * used to calc sumation totaly
     */
    calcItemListForTotal() {
      if (this.items_list.length > 0) {
        // this.data.invoice_subtotal = this.$_.sumBy(this.items_list, (row) => {
        //   return row.subtotal_before_tax ? parseFloat(row.subtotal_before_tax) : 0;
        // });

        /**
         * sum amount before discount
         */
        this.data.total_amount_before_discount = this.$_.sumBy(this.items_list, (row) => {
          return (row.subtotal ? parseFloat(row.subtotal) : 0);
        });

        /**
         * calc discount dependent on
         * discount_type
         * discount_value
         * @private
         */
        let _discount_value = 0;
        if (this.data.discount_type == 3) {
          _discount_value = this.data.discount_value ? parseFloat(this.data.discount_value) : 0;
        } else if (this.data.discount_type == 2) {
          _discount_value = (this.data.discount_value ? (parseFloat(this.data.discount_value) / 100) * this.data.total_amount_before_discount : 0);

        }

        this.data.invoice_total_discount = _discount_value;


        /**
         * sum amount after discount
         */
        this.data.total_amount_after_discount = this.data.total_amount_before_discount - this.data.invoice_total_discount;

        this.data.invoice_subtotal = this.data.total_amount_before_discount;


        /**
         * calc tax
         */
        let _tax = 0;
        if (this.tax) {
          if (this.tax.value_rate) {
            _tax = (this.tax.value_rate ? (parseFloat(this.tax.value_rate) / 100) * this.data.total_amount_before_discount : 0);
          }
        }
        this.data.invoice_total_taxes = _tax;

        this.data.invoice_total = this.$_.sumBy(this.items_list, (row) => {
          return row.subtotal ? parseFloat(row.subtotal) : 0;
        });

        this.data.invoice_total_shipping = (this.data.shipping_fees ? parseFloat(this.data.shipping_fees) : 0);


        /***
         * summation all amount_tax if the tax include tax (1)
         * @private
         */
        // let _include_taxes = this.$_.sumBy(this.items_list, (row) => {
        //   if(row.tax_applied && row.tax_applied[0]){
        //     if(row.tax_applied[0].include_tax == 1){
        //       return row.amount_tax ? parseFloat(row.amount_tax) : 0;
        //     }
        //   }
        //   return 0;
        // });

        this.data.invoice_total_taxes = this.data.invoice_total_taxes ? parseFloat(this.data.invoice_total_taxes) : 0;
        let _shipping_fees = this.data.shipping_fees ? parseFloat(this.data.shipping_fees) : 0;
        this.data.invoice_total = (this.data.total_amount_before_discount + _shipping_fees - _discount_value + _tax);


        this.data.invoice_subtotal = this.data.invoice_subtotal ? this.data.invoice_subtotal.toFixed(2) : 0;
        this.data.invoice_total_discount = this.data.invoice_total_discount ? this.data.invoice_total_discount.toFixed(2) : 0;
        this.data.invoice_total_shipping = this.data.invoice_total_shipping ? this.data.invoice_total_shipping.toFixed(2) : 0;
        this.data.invoice_total_taxes = this.data.invoice_total_taxes ? this.data.invoice_total_taxes.toFixed(2) : 0;
        this.data.invoice_total = this.data.invoice_total ? this.data.invoice_total.toFixed(2) : 0;

      }
    },
    async getDataForPurchaseRequest() {
      let _routeQuotation;
      _routeQuotation = this.quotation_type == 'sales_quotation' ? this.mainRouteForSalesQuotations : this.mainRouteForSalesQuotations
      if (this.sales_request_id)
        await ApiService.get(`${_routeQuotation}/${this.sales_request_id}`).then((response) => {
          this.data.branch_id = response.data.data.branch_id;
          this.branch = response.data.data.branch;
          this.data.notes = response.data.data.notes;
          this.customer = response.data.data.customer;
          this.data.customer_id = response.data.data.customer_id;

          this.items_list = [];

          if (response.data.data.items_list && response.data.data.items_list.length > 0) {
            response.data.data.items_list.forEach((row) => {
              this.items_list_form.item = row.item;
              this.items_list_form.unit_price = row.unit_price;
              this.items_list_form.qty = row.qty;
              this.addItemRowToList();
            });

            this.items_list.forEach((row, index) => {
              this.setValue(index);
            });


          } else {
            this.addItemRowToList();
          }
          this.reloadUploadAttachment = true;
        });
    },
    getTaxSettings() {
      ApiService.get(this.mainTaxSettingsRoute).then((response) => {
        this.tax_settings = response.data.data;
        this.is_company_has_tax_no = response.data.data.is_company_has_tax_no;
        this.data.is_apply_taxes_invoice = response.data.data.is_apply_taxes_setting;
      });
    },
    async defaultDataForUser() {
      await ApiService.get(this.mainRouteDependency + "/default_data_for_user").then((response) => {
        this.data.currency_id = response.data.data.currency_id;
        this.data.inventory_id = response.data.data.inventory_id;
        this.inventory = response.data.data.inventory;
        this.unit_default = response.data.data.unit;


      });
    },
    async getTaxesRulesForTaxSetting() {
      await ApiService.get(this.mainRouteDependency + "/taxes_rules_for_tax_setting/1").then((response) => {
        this.taxes_rules_for_tax_setting = response.data.data;
        response.data.data.forEach((row) => {
          if (row.auto_apply) {
            this.data.apply_tax_rules.push({tax_rule_id: row.id});
          }
        })

      });
    },


    /**
     * payment functions
     * @param payment_method
     */
    onPaymentMethodChanged(payment_method) {
      this.initial_payment_method = payment_method;
      this.bank = null;
      this.treasury = null;
    },
    getPaymentMethods() {
      ApiService.get(this.mainRouteDependency + "/payments_methods").then((response) => {
        this.payment_methods = response.data.data;
      });
    },
    getTreasuries() {
      ApiService.get(`${this.mainRouteDependency}/treasuries`).then((response) => {
        this.treasuries = response.data.data;
      });

    },
    getBanks(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/banks`, {params: {filter: filter}}).then((response) => {
          this.banks = response.data.data;
        });
      } else {
        this.banks = [];
      }
    },


    getDamage() {
      ApiService.get(`${this.mainRouteDependency}/damages`).then((response) => {
        this.damage_list = response.data.data;
      });
    },
    getStains() {
      ApiService.get(`${this.mainRouteDependency}/stains`).then((response) => {
        this.stains_list = response.data.data;
      });
    },

    async getLaundryServices() {
      await ApiService.get(`${this.mainRouteDependency}/laundry_services`).then((response) => {
        this.services_list = response.data.data;

      });
    },
    getLaundryServicesColors() {
      ApiService.get(`${this.mainRouteDependency}/laundry_services_colors`).then((response) => {
        this.colors_list = response.data.data;
      });
    },
    setTax(event) {
      this.data.tax_id = event.id;
      this.calcItemListForTotal();
    },
    copyAttributes(index_item_selected, index) {
      let colors = this.items_list[index_item_selected].colors[index];
      let damages = this.items_list[index_item_selected].damages[index];
      let stains = this.items_list[index_item_selected].stains[index];
      let notes = this.items_list[index_item_selected].notes[index];

      let _count = this.toIntegerVal(this.items_list[index_item_selected].qty);
      for (let i = 0; i < _count; i++) {
        this.items_list[index_item_selected].colors[i] = [...colors];
        this.items_list[index_item_selected].damages[i] = [...damages];
        this.items_list[index_item_selected].stains[i] = [...stains];
        this.items_list[index_item_selected].notes[i] = notes;

      }
      this.refreshCart();
    },
    addMoreOrLessQTY(index) {
      let index_item_selected = index;
      let colors = this.items_list[index_item_selected].colors.length;
      let damages = this.items_list[index_item_selected].damages.length;
      let stains = this.items_list[index_item_selected].stains.length;
      let notes = this.items_list[index_item_selected].notes.length;

      let _count = this.toIntegerVal(this.items_list[index_item_selected].qty);
      if (colors < _count) {
        this.items_list[index_item_selected].colors.push([null, null, null]);
      } else {
        this.items_list[index_item_selected].colors.splice(colors -1, 1);
      }
      if (damages < _count) {
        this.items_list[index_item_selected].damages.push([null, null, null]);
      } else {
        this.items_list[index_item_selected].damages.splice(damages -1, 1);
      }
      if (stains < _count) {
        this.items_list[index_item_selected].stains.push([null, null, null]);
      } else {
        this.items_list[index_item_selected].stains.splice(stains -1, 1);
      }
      if (notes < _count) {
        this.items_list[index_item_selected].notes.push('');
      } else {
        this.items_list[index_item_selected].notes.splice(notes -1, 1);
      }


      this.refreshCart();
    },
    refreshCart() {
      let _items_all = this.items_list;
      this.items_list = [];
      this.items_list = _items_all;
      // this.forSetColorAndReasonsActiveForCurrentItem(this.index_sub_item_selected);
    },
    toIntegerVal(val) {
      return val ? parseInt(val) : 0;
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.laundry_sales_invoices_management"), route: '/sales/sales-laundries'}, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);

    this.getTaxesRulesForTaxSetting();
    this.getTaxSettings();
    this.getCurrencies();
    // this.getTaxes();
    // this.getItems();
    // this.getUnits();
    this.getShippingDetails();
    // this.getCustomers();
    this.getStatus();
    this.getPricesLists();
    this.getInventories();
    this.getAccounts();

    this.getDamage();
    this.getStains();
    this.getLaundryServices();
    this.getLaundryServicesColors();

    /**
     * payment function execution
     */
    this.getPaymentMethods();
    this.getTreasuries();

    // this.getBranches();
    // this.getDefaultCurrency();
    if (!this.data.account_id) {
      this.getDefaultAccount();
    }

    // let promise = this.getDefaultTax();
    // let promise2 = this.getDefaultUnit();
    // Promise.all([promise, promise2]).then(() => {
    //   if (this.items_list.length <= 0) {
    //     this.addItemRowToList();
    //   }
    // });

    if (this.idClone) {
      this.getData();
    }
    if (this.idEditing) {
      this.getData();
    } else {
      let promise = this.defaultDataForUser();
      Promise.all([promise]).then(() => {
        if (this.items_list.length <= 0) {
          this.addItemRowToList();
        }
      });
      this.getPlanMessage();

      this.getCode();

      // this.data.invoice_date = new Date().toISOString().substr(0, 10);
      // this.data.issue_date = new Date().toISOString().substr(0, 10);

      this.getTimeZone().then((res)=>{
        this.data.invoice_date = res;
        this.data.issue_date = res;
      });

      this.getDataForPurchaseRequest();


    }

    // if (!this.idClone && !this.idEditing) {
    //   this.data.invoice_date = new Date().toISOString().slice(0, 10);
    //   this.data.issue_date = new Date().toISOString().slice(0, 10);
    //
    // }
  },
};
</script>